<template>
  <div
    :class="$style.root"
    v-html="html"
  />
</template>

<script setup>
const props = defineProps({
  string: {
    type: String,
    required: true
  },
  forceTargetBlank: {
    type: Boolean,
    default: true
  }
})

const html = baseStringToHtml(props.string, props.forceTargetBlank)
</script>

<style module>
  .root {
  }

  .root h1,
  .root h2,
  .root h3,
  .root h4,
  .root h5,
  .root h6,
  .root h1 *,
  .root h2 *,
  .root h3 *,
  .root h4 *,
  .root h5 *,
  .root h6 {
    font-size: var(--core-text--heading--font-size, var(--font-default--font-size));
    line-height: var(--core-text--heading--line-height, var(--font-default--line-height));
    letter-spacing: var(--core-text--heading--letter-spacing, var(--font-default--letter-spacing));
    text-transform: uppercase;

    font-style: inherit;
    font-weight: normal;

    margin-top: 0;
    margin-bottom: var(--core-text--heading--margin-bottom, calc(var(--lh, 1em) * 1));

  }

  .root h3,
  .root h3 * {
    font-size: var(--core-text--heading--font-size, var(--font-default--font-size));
    line-height: var(--core-text--heading--line-height, var(--font-default--line-height));
    letter-spacing: var(--core-text--heading--letter-spacing, var(--font-default--letter-spacing));

    font-style: inherit;
    font-weight: normal;

    margin-top: 0;
    margin-bottom: var(--core-text--heading--margin-bottom, calc(var(--lh, 1em) * 1));

  }

  .root h1:not([data-first-node]),
  .root h2:not([data-first-node]),
  .root h3:not([data-first-node]),
  .root h4:not([data-first-node]),
  .root h5:not([data-first-node]),
  .root h6:not([data-first-node]) {
    margin-top: var(--core-text--heading--margin-top, calc(var(--lh, 1em) * 2));
  }

  .root a {
    color: inherit;
    text-decoration-line: underline;
    text-decoration-color: var(--text-decoration-color, currentColor);
  }

  .root a:hover {
    text-decoration-line: none;
  }

  .root a * {
    text-decoration-line: none;
  }

  .root code {
    font-family: inherit;
  }

  .root ul,
  .root ol {
    padding: 0;
    margin: 0;
    margin-bottom: var(--lh, 1em);
    list-style-position: inside;
  }

  .root ol {
    list-style: none;
  }

  .root ol li,
  .root ul li {
    counter-increment: list-item;
    position: relative;
  }

  .root ol li {
    padding-left: 2ch;
  }

  .root ul li {
    padding-left: 2ch;
  }


  .root ol li::before {
    content: counter(list-item, decimal) ".\a0";
    position: absolute;
    top: 0;
    left: 0;
  }

  .root ul {
    list-style: none;
  }

  .root ul li::before {
    content: "–";
    position: absolute;
    top: 0;
    left: 0;
  }

  .root p {
    margin-bottom: var(--lh, 1em);
    margin-top: 0;
  }

  .root p:empty {
    display: none;
  }

  .root em,
  .root i {
  }

  .root u {
  }

  .root b,
  .root strong {
  }

  .root sup,
  .root sub {
    line-height: 0;
    font-size: 75%;
  }

  .root blockquote {
    margin: 0;
    padding: var(--unit--vertical) var(--unit--horizontal);
    font-style: italic;

    margin-bottom: var(--lh, 1em);
  }

  .root blockquote * {
    display: inline;
  }

  .root p + blockquote {
    /* margin-top: calc(var(--unit--vertical) * 1); */
  }

  .root > [data-last-node] {
    margin-bottom: 0;
  }

  .root code {
    white-space: normal;
    font-family: inherit;
  }
</style>
